import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Config } from '../../common/config';
import '../../css/cd/cd_style.css';
import { format, parseISO } from 'date-fns';
import { ko } from "date-fns/locale";
import CollectionDateEnd from './collection_date_end';

class CollectionDate extends Component {

    constructor(props) {
        super(props);

        this.orderNumber = props.match.params.orderNumber

        this.state = {
            collection_date: '',
            itemName: '',
            receiverName: '',
            senderName: '',
            returnPickupTargetedAt: '',
            openAlert: false,
            alertTitle: '',
            alertMessage: '',
            enabledButton: false,
            loadingSave: false,
            originTargetDate: '',
            originTargetDateText: '',
            originTargetDateTimestamp: 0,
            nextTargetDate: '',
            nextTargetDateText: '',
            nextTargetDateTimestamp: 0,
            selectedTargetDate: 0,
            isAvailableChangeTargetDate: true,
        }
    }

    componentDidMount() {
        if (!this.isEmpty(this.orderNumber)) {
            this.fetchTackOrder();
        }
    }

    async fetchTackOrder() {
        let url = Config.api.cs_url + "/order/tracking/" + this.orderNumber;

        try {
            const response = await fetch(url, {
                method: "GET",
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            })
            if (!response.ok) { throw response }
            const body = await response.json()

            if (body.result === 'Y') {
                if (body.complete === true) {
                    // Redirect
                    window.location.replace(Config.api.track_url + '/?trackingNumber=' + this.orderNumber);
                    return;
                }

                if (body.prioirty !== 50) {
                    this.setState({
                        isValidOrderNumber: false,
                    });
                    return
                }

                body.trackingDetails.forEach((detail) => {
                    if (detail.kind === "픽업 완료") {
                        window.location.replace(Config.api.track_url + '/?trackingNumber=' + this.orderNumber);
                        return;
                    }
                })

                let itemName = body.itemName
                let receiverName = body.receiverName
                let senderName = body.senderName
                let returnPickupTargetedAt = body.returnPickupTargetedAt
                let originTargetPickupDate = body.originTargetPickupDate
                let originTargetPickupDateTimestamp = parseISO(originTargetPickupDate)

                let limitDate = (originTargetPickupDateTimestamp / 1000) + (60 * 60 * 14)
                let currentDate = (new Date().getTime() / 1000)
                currentDate = Math.floor(currentDate);

                if (currentDate > limitDate) {
                    this.setState({
                        isAvailableChangeTargetDate: false,
                    });
                    return
                }

                originTargetPickupDate = format(originTargetPickupDateTimestamp, 'MM-dd(E)', { locale: ko });
                let nextTargetPickupDate = body.nextTargetPickupDate
                let nextTargetDateTimestamp = parseISO(nextTargetPickupDate)
                nextTargetPickupDate = format(nextTargetDateTimestamp, 'MM-dd(E)', { locale: ko });

                this.setState({
                    itemName: itemName,
                    receiverName: receiverName,
                    senderName: senderName,
                    returnPickupTargetedAt: returnPickupTargetedAt,
                    isValidOrderNumber: true,
                    originTargetDate: originTargetPickupDate,
                    originTargetDateText: body.originTargetPickupDate,
                    originTargetDateTimestamp: originTargetPickupDateTimestamp / 1000,
                    nextTargetDate: nextTargetPickupDate,
                    nextTargetDateText: body.nextTargetPickupDate,
                    nextTargetDateTimestamp: nextTargetDateTimestamp / 1000,
                });

            } else {
                // TODO Error
                this.setState({
                    isValidOrderNumber: false,
                });
            }
        } catch (err) {
            console.log(err)

            this.setState({
                isValidOrderNumber: false,
            });
        }
    }

    async changeTargetDate() {
        this.setState({
            loadingSave: true,
        });

        try {
            let url = Config.api.cs_url + "/order/return-pickup-target-date";

            const selectedTargetDateTimestamp = parseInt(this.state.selectedTargetDate, 0);

            const response = await fetch(url, {
                method: "PATCH",
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    orderNumber: this.orderNumber,
                    targetDate: selectedTargetDateTimestamp,
                })
            })
            if (!response.ok) { throw response }

            this.fetchTackOrder();

            const changeTargetDate = format(selectedTargetDateTimestamp * 1000, 'MM-dd(E)', { locale: ko });

            this.openAlertDialog('알림', changeTargetDate + '로 수거일이 설정되었습니다.')

            this.setState({
                loadingSave: false,
            });
        } catch (err) {
            console.log(err)

            this.setState({
                loadingSave: false,
            });
        }
    }

    handleAlertClose = () => {
        this.setState({
            openAlert: false,
        })
    }

    openAlertDialog = (title, message) => {
        this.setState({
            openAlert: true,
            alertTitle: title,
            alertMessage: message,
        });
    }

    onChangedTargetDate = (e) => {
        this.setState({
            selectedTargetDate: e.target.value,
            enabledButton: true,
        });
    }

    onClickedSave = () => {
        if (this.state.enabledButton !== true) {
            return
        }

        // 날짜 저장
        this.changeTargetDate();
    }

    isEmpty = (value) => {
        if (value === "" || value == null || value === undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
            return true
        } else {
            return false
        }
    };

    render() {
        return (
            this.state.isAvailableChangeTargetDate !== true ? <CollectionDateEnd /> :
                <div>
                    <header className="header_cd">
                        <div class="logo_wrap">
                            <div class="logo"></div>
                        </div>
                    </header>
                    <main className='main_cd'>
                        {this.state.isValidOrderNumber === false ?
                            <div className="invalid_order">
                                <div className="place_info_text">배송 정보를 조회하지 못했습니다.<br />송장번호를 다시 확인해 주세요.</div>
                            </div>
                            :
                            <div className='valid_order'>
                                <div className="default_info_title">기본 정보</div>
                                <table className="default_info_table">
                                    <tbody>
                                        <tr className='table_row'>
                                            <td className='table_title'>송장번호</td>
                                            <td className='table_data'>{this.orderNumber}</td>
                                        </tr>
                                        <tr className='table_row'>
                                            <td className='table_title'>물품명</td>
                                            <td className='table_data'>{this.state.itemName}</td>
                                        </tr>
                                        <tr className='table_row'>
                                            <td className='table_title'>보내는 분</td>
                                            <td className='table_data'>{this.state.senderName}</td>
                                        </tr>
                                        <tr className='table_row'>
                                            <td className='table_title'>받는 분</td>
                                            <td className='table_data'>{this.state.receiverName}</td>
                                        </tr>
                                        <tr className='table_row'>
                                            <td className='table_title'>수거일</td>
                                            <td className='table_data'>{this.state.returnPickupTargetedAt}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className='change_collection_date_title'>수거일 변경</div>

                                <div className='change_info_title'>
                                    <input id="rbtn_origin_target_date"
                                        className='radio'
                                        name="origin"
                                        value={this.state.originTargetDateTimestamp}
                                        type="radio"
                                        readOnly={true}
                                        checked={this.state.selectedTargetDate !== 0 && this.state.selectedTargetDate.toString() === this.state.originTargetDateTimestamp.toString()}
                                        onClick={this.onChangedTargetDate}
                                    />
                                    <label className="target_date_label" htmlFor="rbtn_origin_target_date">
                                        <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_door" />
                                        <div className="label_font">{this.state.originTargetDate} (으)로 {this.state.originTargetDateText === this.state.returnPickupTargetedAt ? "유지할게요." : "변경해주세요."}</div>
                                    </label>
                                    <input id="rbtn_next_target_date"
                                        className='radio'
                                        name="next"
                                        value={this.state.nextTargetDateTimestamp}
                                        type="radio"
                                        readOnly={true}
                                        checked={this.state.selectedTargetDate !== 0 && this.state.selectedTargetDate.toString() === this.state.nextTargetDateTimestamp.toString()}
                                        onClick={this.onChangedTargetDate}
                                    />
                                    <label className="target_date_label" htmlFor="rbtn_next_target_date">
                                        <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_door" />
                                        <div className="label_font">{this.state.nextTargetDate} (으)로 {this.state.nextTargetDateText === this.state.returnPickupTargetedAt ? "유지할게요." : "변경해주세요."}</div>
                                    </label>
                                </div>

                                <div className='notice_container'>
                                    <div className='notice_title'>
                                        <img src="https://cdn.0br1.io/img/icons/aler_circle_72x72.png" alt="alert_icon" />
                                        <div className="notice_desc_font">확인해주세요!</div>
                                    </div>
                                    <div className="notice_desc_font">
                                        반품 접수 취소, 상품 위탁장소 변경, 수거일 변경 관련 문의는 카카오톡 고객센터로 문의해 주세요.
                                    </div>
                                </div>
                            </div>
                        }
                    </main>
                    {this.state.isValidOrderNumber === true ?
                        <footer className='footer_cd'>
                            {
                                this.state.loadingSave === true ?
                                    <div className='spinner_container'>
                                        <CircularProgress className="spinner" size='50px' />
                                    </div>
                                    : <button
                                        className={this.state.enabledButton ? "save_button_enabled" : "save_button_disabled"}
                                        type="button"
                                        onClick={() => this.onClickedSave()}>
                                        저장하기
                                    </button>
                            }
                        </footer>
                        :
                        <div />
                    }
                    <Dialog
                        open={this.state.openAlert}
                        onClose={this.handleAlertClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.alertTitle == null ? '' : this.state.alertTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>{typeof this.state.alertMessage === 'string' ? this.state.alertMessage.split("\n").map((letter) => (<>{letter}<br /></>)) : this.state.alertMessage}</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleAlertClose}>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
        );
    }
}

export default CollectionDate;