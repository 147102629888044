import React, { Component } from 'react';

class DeepLinkRedirect extends Component {
    constructor(props) {
        super(props);

        this.link = props.match.params.link;
    }

    componentDidMount() {
        window.open(`https://pingpongdp.supalink.cc/${this.link}`, "_blank", "noopener, noreferrer");
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default DeepLinkRedirect;