import React, { Component } from 'react';
import '../../css/cd/cd_style.css';

class CollectionDateEnd extends Component {

    onClickedHelp = () => {
        window.location.href = 'https://pf.kakao.com/_xidxkrK/chat';
    }

    render() {
        return (
            <div>
                <header className="header_cd">
                    <div class="logo_wrap">
                        <div class="logo"></div>
                    </div>
                </header>
                <main className='main_cd'>
                    <div className='collection_date_end_title'>
                        변경 가능 시간이 <br />
                        지났습니다
                    </div>
                    <table className='table_desc'>
                        <tr>
                            <td className='collection_date_end_content_point'>• </td>
                            <td className='collection_date_end_content'>이전에 이미 수거일을 변경한 경우, 직접변경이 불가합니다.</td>
                        </tr>
                        <tr>
                            <td className='collection_date_end_content_point'>• </td>
                            <td className='collection_date_end_content'>반품 접수 취소, 상품 위탁장소 변경, 수거일 변경 관련 문의는 카카오톡 고객센터로 문의해 주세요.</td>
                        </tr>
                    </table>
                </main>
                <footer className='footer_cd'>
                    {
                        <button
                            className="save_button_enabled"
                            type="button"
                            onClick={() => this.onClickedHelp()}>
                            수거일 변경 문의하기
                        </button>
                    }
                </footer>
            </div>
        );
    }
}

export default CollectionDateEnd;